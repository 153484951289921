import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../constants";

const MainLogo: React.FC = () => {
  return (
    <NavigationLink id="lucaSteelLogo" to="/">
      <SVG
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 405.16"
      >
        <Polygon points="224.9 201.85 201.03 285.97 183.48 285.97 207.35 201.85 200.51 177.75 140.57 177.75 145.36 160.87 213.27 160.87 224.9 201.85" />
        <Polygon points="193.07 201.75 169.17 285.97 126.09 285.97 117.54 255.82 135.09 255.82 138.86 269.08 156.41 269.08 173.11 210.21 104.6 210.21 102.2 201.75 126.09 117.52 169.17 117.52 177.72 147.67 160.17 147.67 156.41 134.4 138.86 134.4 122.14 193.32 190.68 193.32 193.07 201.75" />
        <Polygon points="154.7 225.73 149.91 242.62 81.99 242.62 70.36 201.63 94.22 117.52 111.78 117.52 87.92 201.64 94.75 225.73 154.7 225.73" />
        <Path d="M224.07,86.87H71.19l-32.59,114.88,32.59,114.87H224.07l32.59-114.87-32.59-114.88Zm-12.76,212.86H83.95l-27.8-97.98,27.8-97.99h127.36l27.8,97.99-27.8,97.98Z" />
        <g>
          <Path d="M387.52,255.81l-8.05,58.61h-22.56c-2.07-9-3.89-19.25-5.47-30.73-.72,4.92-2.4,15.17-5.04,30.73h-22.44l-8.09-58.61h17.56l1.91,20.45,1.85,19.77c.67-10.23,2.33-23.64,5-40.22h18.77c.25,1.71,.91,8.16,1.97,19.33l2,22.3c1.03-14.19,2.71-28.06,5.04-41.63h17.56Z" />
          <Path d="M391.08,255.81h30.14v11.73h-12.06v11.11h11.29v11.15h-11.29v12.89h13.27v11.73h-31.34v-58.61Z" />
          <Path d="M445.68,255.81v46.88h10.99v11.73h-29.06v-58.61h18.07Z" />
          <Path d="M461.05,255.81h13.52c8.73,0,14.63,.34,17.71,1.01,3.07,.68,5.42,1.79,7.02,3.33,1.6,1.55,2.6,3.26,3,5.16,.4,1.89,.6,5.62,.6,11.17v20.52c0,5.26-.29,8.78-.88,10.55-.59,1.77-1.61,3.16-3.07,4.16-1.46,1-3.26,1.7-5.41,2.1-2.15,.4-5.38,.6-9.7,.6h-22.79v-58.61Zm18.07,10.03v38.55c2.61,0,4.21-.44,4.81-1.32,.6-.88,.9-3.28,.9-7.19v-22.77c0-2.65-.1-4.36-.3-5.1-.2-.75-.66-1.3-1.37-1.65-.72-.35-2.06-.52-4.04-.52Z" />
          <Path d="M527.72,255.81v58.61h-18.07v-58.61h18.07Z" />
          <Path d="M575.32,255.81v58.61h-15.84l-9.4-26.64v26.64h-15.11v-58.61h15.11l10.13,26.39v-26.39h15.11Z" />
          <Path d="M624.04,277.38h-18.07v-5.32c0-3.35-.17-5.45-.52-6.3-.35-.85-1.16-1.27-2.45-1.27-1.12,0-1.87,.36-2.27,1.09-.4,.72-.6,2.58-.6,5.57v28.13c0,2.63,.2,4.36,.6,5.19,.4,.83,1.2,1.25,2.4,1.25,1.32,0,2.21-.47,2.68-1.41,.47-.94,.71-2.77,.71-5.5v-6.95h-3.65v-8.9h21.16v31.46h-11.36l-1.67-4.2c-1.23,1.81-2.78,3.17-4.66,4.07-1.88,.91-4.09,1.36-6.64,1.36-3.04,0-5.88-.62-8.53-1.86-2.65-1.24-4.66-2.78-6.04-4.62-1.37-1.83-2.23-3.76-2.58-5.77s-.52-5.04-.52-9.07v-17.41c0-5.6,.36-9.66,1.07-12.2,.71-2.53,2.77-4.86,6.16-6.97,3.39-2.11,7.78-3.17,13.16-3.17s9.69,.92,13.18,2.75c3.49,1.83,5.77,4.01,6.82,6.53,1.06,2.52,1.59,6.18,1.59,10.99v2.53Z" />
          <Path d="M692.39,292.15v9.88l-4.84,2.61,6.06,9.77h-16.06l-2.09-3.26c-5.38,2.99-10.47,4.49-15.28,4.49s-8.91-1.41-11.63-4.23c-2.72-2.82-4.08-6.23-4.08-10.21,0-3.19,.8-5.68,2.4-7.49,1.6-1.81,4.01-3.28,7.21-4.42-4.58-2.77-6.87-6.53-6.87-11.26,0-4.08,1.63-7.36,4.89-9.85,3.26-2.49,7.76-3.73,13.48-3.73,5.15,0,9.21,1.22,12.17,3.66,2.96,2.44,4.44,5.54,4.44,9.3,0,3.96-1.8,7.47-5.41,10.53l6.12,9.33,9.47-5.13Zm-22.86,12.39l-5.6-7.9c-2.15,1.64-3.22,3.41-3.22,5.32,0,1.38,.33,2.41,1,3.1s1.66,1.03,2.98,1.03c1.4,0,3.01-.52,4.84-1.55Zm-3.88-22.64c1.95-2.16,2.92-4.21,2.92-6.18,0-.85-.29-1.62-.86-2.33-.57-.7-1.37-1.05-2.4-1.05-.95,0-1.66,.27-2.15,.8s-.73,1.34-.73,2.44c0,2.28,1.07,4.38,3.22,6.32Z" />
          <Path d="M712.69,255.81h30.61v11.73h-12.54v11.11h11.16v11.15h-11.16v24.61h-18.07v-58.61Z" />
          <Path d="M777.15,255.81l10.33,58.61h-18.47l-.97-10.53h-6.46l-1.09,10.53h-18.68l9.22-58.61h26.12Zm-9.58,37.68c-.92-6.64-1.83-14.84-2.75-24.61-1.84,11.22-3,19.43-3.47,24.61h6.22Z" />
          <Path d="M790.56,255.81h18.03c5.69,0,10.01,.37,12.94,1.12,2.93,.75,5.3,2.26,7.1,4.54,1.8,2.28,2.7,5.95,2.7,11.01,0,3.42-.64,5.81-1.91,7.16-1.28,1.35-3.79,2.39-7.53,3.11,4.18,.8,7.01,2.12,8.5,3.97,1.49,1.85,2.23,4.68,2.23,8.49v5.43c0,3.96-.54,6.89-1.61,8.8-1.07,1.91-2.78,3.21-5.13,3.91-2.35,.7-7.15,1.05-14.42,1.05h-20.91v-58.61Zm18.07,10.03v13.03c.77-.02,1.37-.04,1.8-.04,1.77,0,2.9-.37,3.39-1.1,.48-.74,.73-2.84,.73-6.32,0-1.83-.2-3.12-.6-3.86-.4-.73-.92-1.2-1.57-1.39-.64-.19-1.9-.3-3.76-.33Zm0,22.15v16.4c2.55-.07,4.17-.41,4.87-1.01,.7-.6,1.05-2.09,1.05-4.45v-5.47c0-2.51-.32-4.03-.95-4.56-.63-.53-2.29-.83-4.98-.9Z" />
          <Path d="M839.11,255.81h12.79c8.53,0,14.3,.28,17.32,.83,3.02,.55,5.48,1.97,7.38,4.25,1.9,2.28,2.85,5.92,2.85,10.91,0,4.56-.67,7.63-2.02,9.19-1.35,1.57-3.99,2.51-7.94,2.82,3.58,.75,5.98,1.75,7.21,3.01,1.23,1.25,2,2.41,2.3,3.46,.3,1.05,.45,3.94,.45,8.67v15.46h-16.79v-19.48c0-3.14-.29-5.08-.88-5.83-.59-.75-2.12-1.12-4.61-1.12v26.42h-18.07v-58.61Zm18.07,10.03v13.03c2.03,0,3.46-.24,4.27-.71s1.22-2,1.22-4.58v-3.22c0-1.86-.39-3.08-1.18-3.66-.79-.58-2.23-.87-4.32-.87Z" />
          <Path d="M904.53,255.81v58.61h-18.07v-58.61h18.07Z" />
          <Path d="M953.98,281.33h-18.07v-10.19c0-2.96-.19-4.81-.58-5.54-.39-.73-1.24-1.1-2.55-1.1-1.49,0-2.43,.45-2.83,1.34-.4,.89-.6,2.82-.6,5.79v27.22c0,2.85,.2,4.71,.6,5.57,.4,.87,1.3,1.3,2.7,1.3s2.23-.43,2.64-1.31,.62-2.92,.62-6.13v-7.37h18.07v2.28c0,6.07-.51,10.37-1.52,12.91-1.02,2.54-3.26,4.76-6.74,6.67-3.48,1.91-7.76,2.86-12.86,2.86s-9.66-.81-13.09-2.43c-3.43-1.62-5.71-3.85-6.82-6.71-1.12-2.86-1.68-7.16-1.68-12.9v-17.12c0-4.22,.17-7.39,.52-9.5,.34-2.11,1.37-4.15,3.07-6.1,1.7-1.95,4.06-3.49,7.08-4.61,3.02-1.12,6.49-1.68,10.41-1.68,5.32,0,9.72,.87,13.18,2.6,3.46,1.74,5.74,3.9,6.82,6.49,1.09,2.59,1.63,6.62,1.63,12.09v5.57Z" />
          <Path d="M991.7,255.81l10.33,58.61h-18.47l-.97-10.53h-6.46l-1.09,10.53h-18.68l9.22-58.61h26.12Zm-9.58,37.68c-.92-6.64-1.83-14.84-2.75-24.61-1.84,11.22-3,19.43-3.47,24.61h6.22Z" />
          <Path d="M1036.66,255.81v11.73h-10.73v46.88h-18.07v-46.88h-10.69v-11.73h39.49Z" />
          <Path d="M1058.85,255.81v58.61h-18.07v-58.61h18.07Z" />
          <Path d="M1107.4,290.12c0,5.89-.16,10.06-.49,12.51s-1.36,4.69-3.09,6.71-4.07,3.58-7.02,4.67c-2.95,1.09-6.38,1.63-10.3,1.63s-7.06-.51-10.02-1.54-5.34-2.56-7.15-4.61c-1.8-2.05-2.88-4.28-3.22-6.7-.34-2.41-.52-6.64-.52-12.67v-10.03c0-5.89,.16-10.06,.49-12.51,.33-2.45,1.36-4.69,3.09-6.72,1.73-2.03,4.07-3.58,7.02-4.67,2.95-1.09,6.38-1.63,10.3-1.63,3.72,0,7.06,.51,10.02,1.54,2.96,1.03,5.35,2.57,7.15,4.62,1.8,2.05,2.88,4.28,3.22,6.7,.35,2.41,.52,6.64,.52,12.67v10.03Zm-18.07-19.26c0-2.73-.18-4.47-.54-5.23-.36-.76-1.09-1.14-2.21-1.14-.94,0-1.67,.31-2.17,.92-.5,.61-.75,2.43-.75,5.45v27.37c0,3.4,.17,5.5,.49,6.3,.33,.8,1.1,1.19,2.3,1.19s2.02-.46,2.36-1.38c.34-.92,.52-3.1,.52-6.55v-26.93Z" />
          <Path d="M1154.49,255.81v58.61h-15.84l-9.4-26.64v26.64h-15.11v-58.61h15.11l10.13,26.39v-26.39h15.11Z" />
        </g>
        <g>
          <Path d="M357.63,89.71v108.31h25.39v27.1h-67.15V89.71h41.76Z" />
          <Path d="M488.06,89.71v90.5c0,10.26-.4,17.47-1.19,21.62-.79,4.16-3.14,8.42-7.04,12.8s-9.04,7.69-15.42,9.95c-6.38,2.26-13.9,3.39-22.56,3.39-9.59,0-18.05-1.34-25.39-4.01-7.34-2.68-12.83-6.16-16.47-10.45-3.64-4.29-5.79-8.82-6.45-13.59-.66-4.77-.99-14.79-.99-30.07V89.71h41.76v101.54c0,5.91,.38,9.69,1.14,11.33,.76,1.65,2.3,2.47,4.61,2.47,2.64,0,4.35-.91,5.11-2.72,.76-1.81,1.14-6.09,1.14-12.84V89.71h41.76Z" />
          <Path d="M601.73,148.68h-41.76v-23.53c0-6.84-.45-11.11-1.34-12.81-.89-1.7-2.86-2.55-5.9-2.55-3.44,0-5.62,1.03-6.55,3.09-.93,2.06-1.39,6.52-1.39,13.38v62.9c0,6.58,.46,10.87,1.39,12.88,.93,2.01,3.01,3.01,6.25,3.01s5.14-1,6.1-3.02c.96-2.01,1.44-6.74,1.44-14.17v-17.02h41.76v5.28c0,14.02-1.17,23.96-3.52,29.82-2.35,5.86-7.54,11-15.57,15.41-8.04,4.41-17.94,6.62-29.71,6.62s-22.32-1.87-30.25-5.6c-7.93-3.74-13.19-8.91-15.77-15.51-2.58-6.61-3.87-16.55-3.87-29.82v-39.56c0-9.76,.4-17.07,1.19-21.95,.79-4.88,3.16-9.58,7.09-14.09,3.93-4.52,9.39-8.07,16.36-10.66,6.98-2.59,14.99-3.89,24.05-3.89,12.3,0,22.45,2.01,30.45,6.01,8,4.01,13.26,9.01,15.77,14.99,2.51,5.99,3.77,15.3,3.77,27.94v12.86Z" />
          <Path d="M688.89,89.71l23.88,135.41h-42.67l-2.24-24.34h-14.94l-2.51,24.34h-43.17l21.3-135.41h60.35Zm-22.13,87.07c-2.11-15.34-4.23-34.3-6.36-56.88-4.26,25.93-6.93,44.89-8.02,56.88h14.37Z" />
          <Path d="M808.33,130.7h-38.78v-10.04c0-4.68-.49-7.67-1.49-8.95-.99-1.28-2.65-1.92-4.96-1.92-2.52,0-4.41,.87-5.7,2.59-1.29,1.73-1.94,4.35-1.94,7.87,0,4.52,.72,7.92,2.17,10.21,1.38,2.29,5.32,5.05,11.8,8.28,18.59,9.3,30.3,16.94,35.12,22.9,4.83,5.97,7.24,15.59,7.24,28.86,0,9.65-1.34,16.76-4.02,21.33-2.68,4.57-7.85,8.41-15.52,11.5-7.67,3.1-16.6,4.64-26.78,4.64-11.18,0-20.71-1.78-28.61-5.35-7.9-3.57-13.08-8.11-15.52-13.63-2.45-5.52-3.67-13.35-3.67-23.5v-8.87h38.78v16.48c0,5.07,.55,8.34,1.64,9.79,1.09,1.45,3.03,2.17,5.8,2.17s4.84-.92,6.2-2.76,2.03-4.57,2.03-8.2c0-7.97-1.29-13.19-3.87-15.64-2.65-2.45-9.16-6.55-19.54-12.29-10.38-5.8-17.26-10.01-20.63-12.63-3.37-2.62-6.17-6.24-8.38-10.87-2.22-4.63-3.32-10.54-3.32-17.73,0-10.37,1.57-17.95,4.71-22.75,3.14-4.79,8.22-8.54,15.23-11.25,7.01-2.7,15.47-4.06,25.39-4.06,10.84,0,20.09,1.48,27.72,4.43,7.64,2.96,12.69,6.68,15.17,11.17,2.48,4.49,3.72,12.11,3.72,22.88v5.35Z" />
          <Path d="M909.01,89.71v27.1h-24.8v108.31h-41.76V116.81h-24.7v-27.1h91.25Z" />
          <Path d="M918.53,89.71h69.63v27.1h-27.87v25.68h26.09v25.76h-26.09v29.78h30.65v27.1h-72.41V89.71Z" />
          <Path d="M1002.94,89.71h69.63v27.1h-27.87v25.68h26.09v25.76h-26.09v29.78h30.65v27.1h-72.41V89.71Z" />
          <Path d="M1129.1,89.71v108.31h25.39v27.1h-67.15V89.71h41.76Z" />
        </g>
      </SVG>
    </NavigationLink>
  );
};

export default MainLogo;

const SVG = styled.svg`
  width: 75%;
`;

const Polygon = styled.polygon`
  fill: ${COLORS.white};
`;

const Path = styled.path`
  fill: ${COLORS.white};
`;

const NavigationLink = styled(NavLink)`
  text-decoration: none;
`;
